<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
      <p class="lead">Contact Us – Block Me Registration Issue</p>

      <p>
        Please send us a note about the issue you have experienced with the "Block Me" registration process.
      </p>
  
      <div v-if="error" class="alert alert-danger">{{error}}</div>

      <div v-if="message" class="alert alert-success">
        <p class="lead">Thank you for your message.</p>
        <p>We will work on the issue you have reported and will get back to you as soon as we are able to identify and resolve the issue.  </p>
				<p>Should you have any immediate questions, please contact <a href="mailto:webmaster@malabs.com">webmaster</a> for assistence.</p>
				    
      </div>
      

      <form class=" g-3">
        <div class="row">
          <div class="col-md-5" :class="{'alert alert-danger': data.email.error}">
            <label class="form-label">Email Address <span class="text-danger">*</span></label>
            <div v-if="data.email.error">{{data.email.message}}</div>
            <input type="email" class="form-control" v-model="data.email.input" maxlength="100">
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-5" >
            <label class="form-label">Message </label>
            <textarea class="form-control" v-model="data.note.input"></textarea>
          </div>
        </div>

        <p class="mt-5">
        <i class="bi bi-info-circle-fill icon me-2"></i><a class="anchor" @click="handleInfo()">What additional file information is helpful to our completion of your "Block Me" request?  </a>
        <div class="border p-4 bg-light shadow rounded" id="info">
          <p>
          The inclusion of an additional file attachment can help us evaluate, identify, and remedy any issue in completing the "Block Me" registration process. If you continue to receive emails from our sales personnel, you can paste such e-mail here to help us troubleshoot the issue. Be sure to include message headers in the file, because they contain important information that will enable us to trace the origin of the email(s) that you receive. If you receive email from more than one source, please attach examples from each sender.  
          </p>

          
          <p>View message headers in Outlook on your desktop</p>
          <ol>
          <li>Double-click an email message to open it outside of the Reading Pane.</li>
          <li>Click File > Properties.</li>
          <li>Header information appears in the Internet headers box.
            <br/>
            Tip: You can highlight the information in that box, press Ctrl+C to copy, and paste it into Notepad or Word to see the entire header at once.
          </li>
          </ol>
          
        </div>
        </p>
        
        <div class="row"> 
          <div class="col mt-5">
              <button v-if="waiting" class="btn btn-primary btn" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Submit
                </button>
              <button v-else type="submit" class="btn btn-primary btn" @click.prevent="handleSubmit">Submit</button>
          </div>
        </div>
      </form>

    

    
  </div>

  <div id="google_recaptcha"></div>
</template>

<script>
import BreadcrumbPage from "../../components/BreadcrumbPage.vue"
import { ref, onMounted } from 'vue'
import $ from 'jquery'
import { insertReCaptchaScript, handleReCaptcha, checkEmail } from '../../utils/common'
import { getAPI } from '../../utils/axios-api'

export default {
  name: 'BlockMeReport',
  components: { BreadcrumbPage },
  setup() {
    const waiting = ref(false)
    const error = ref(null)
    const message = ref(null)
    const data = ref({
      email: {input: null, error: false, message: ""},
      note: {input: null, error: false},
    })
    
    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "Home", link: "Home"})
    breadcrumb_links.value.push({text: "Block Me Registration", link: ""})

    //add script for google reCaptcha to template
    onMounted(() => {
        insertReCaptchaScript("google_recaptcha")
    })

    function validate() {
      let valid = true

      if (data.value.email.input != "" && data.value.email.input != null) {
        if (checkEmail(data.value.email.input) == true)
          data.value.email.error = false
        else {
          valid = false 
          data.value.email.error = true
          data.value.email.message = "The email address is invalid."
        }
      }
      else {
        valid = false 
        data.value.email.error = true
        data.value.email.message = "The email address is required."
      }

      

      return valid
    }

    const handleSubmit = async () => {
      waiting.value = true
      error.value = null
      message.value = null

      let result = await handleReCaptcha(grecaptcha)
      if (result["is_human"] == true) {
        //validate the form
        if (validate() == false) {
          waiting.value = false
          return 0
        }
        
        let payload = {
          email: data.value.email.input,
          note: data.value.note.input,
        }
        
        data.value.email.input = null
        data.value.note.input = null
        
        getAPI.post('/account/blockme/report/', payload)
        .then((response) => {
          let res = response.data

          message.value = res["message"]
          
          waiting.value = false
        })
        .catch(err => {
          error.value = err.response.data.message
          waiting.value = false
        })
      }
      else {
        //if not pass google reCaptcha, show the error
        waiting.value = false
        error.value = result["message"]
      }

    }

    //show or hide the info explaination layer
    const handleInfo = () => {
      $("#info").slideToggle("slow")
    }

    return {
      waiting,
      error,
      message,
      data,
      handleSubmit,
      handleInfo,
      breadcrumb_links
    }

  }
}
</script>

<style scoped>
.lead {
  font-size: 26px;
  font-family: "Roboto";
  font-weight: 600;
  color: #555555;
}
.anchor {
  cursor: pointer;
  color:#000;
}
.anchor:hover {
  color:cornflowerblue;
}
#info {
  display:none;
}
</style>